<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-row">
				<pui-field-set :title="$t('pmparameter.title')">
					<v-row no-gutters>
						<v-col cols="12" md="4">
							<pui-text-field :label="$t('pmparameter.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<pui-text-field
								:label="$t('pmparameter.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="20"
							></pui-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<pui-select
								attach="pmunitid"
								:label="this.$t('pmparameter.unit')"
								:placeholder="this.$t('pmparameter.phunit')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmunit"
								:modelFormMapping="{ pmunitid: 'pmunitid' }"
								:itemsToSelect="unitItemsToSelect"
								itemValue="pmunitid"
								:itemText="(item) => (item.symbol ? item.name + ' (' + item.symbol + ')' : item.name)"
							></pui-select>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmparameter.description')"
								maxlength="2048"
								toplabel
							></pui-text-area>
						</v-col>
					</v-row>
					<v-layout wrap>
						<v-col xs12 md2>
							<pui-checkbox
								:label="$t('pmparameter.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								class="mt-2"
							></pui-checkbox>
						</v-col>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'PmSatelliteParameterForm',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'pmsatelliteparameter'
		};
	},
	computed: {
		unitItemsToSelect() {
			return [{ pmunitid: this.model.pmunitid }];
		}
	},
	watch: {},
	mounted() {},
	updated() {},
	methods: {}
};
</script>
